import React, { Component } from "react";

import { HashRouter, Route, Switch } from "react-router-dom";
import "./index.css";
// import LocalizedStrings from "react-localization";
//import { renderRoutes } from 'react-router-config';
import "./App.scss";
import IdleTimeOutHandler from "./views/IdleTimeOutHandler";
import PrintView from "./views/Forms/PrintView";
const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const DefaultHeader = React.lazy(() =>
  import("./containers/DefaultLayout/DefaultHeader")
);
const Login = React.lazy(() => import("./views/Pages/Login"));
const PrintReports = React.lazy(() => import("./views/Forms/PrintReports"));

const DealPrint = React.lazy(() =>
  import("./views/Forms/HarisForms/components/dealPrint")
);
const AdminSuppDashboard = React.lazy(() =>
  import("./views/Forms/admen/AdminSuppDashboard")
);
const AdminViewDetails = React.lazy(() =>
  import("./views/Forms/admen/adminViewDetails")
);
const SignUp = React.lazy(() => import("./views/Pages/SignUp/SignUp"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));
let strings = {
  en: {
    invoice: "Come vuoi il tuo uovo oggi",
    boiledEgg: "Boiled egg",
    softBoiledEgg: "Soft-boiled egg",
    choice: "How to choose the egg",
  },
  it: {
    invoice: "Come vuoi il tuo uovo oggi?",
    boiledEgg: "Uovo sodo",
    softBoiledEgg: "Uovo alla coque",
    choice: "Come scegliere l'uovo",
  },
};
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: "en",
    };

    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }
  handleLanguageChange(e) {
    e.preventDefault();

    let lang = e.target.value;

    // this.setState((prevState) => ({
    //   language: lang,
    // }));
    this.setState({
      language: lang,
    });
  }
  render() {
    //strings.setLanguage(this.state.language);
    return (
      <>
        {/* {window.location.href.slice(-1) == "/" ||
        window.location.href.slice(-5) == "login" ? null : (
          <IdleTimeOutHandler
            onActive={() => {
              this.setState({
                setIsActive: true,
              });
            }}
            onIdle={() => {
              this.setState({
                setIsActive: false,
              });
            }}
            onLogout={() => {
              window.location.href = "/";
              localStorage.setItem("token", null);
            }}
            refresh={() => {}}
          />
        )} */}
        <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/PrintReports"
                name="PrintReports"
                render={(props) => <PrintReports {...props} />}
              />
              <Route
                exact
                path="/Prints"
                name="Prints"
                render={(props) => <PrintView {...props} />}
              />
              <Route
                exact
                path="/emailInvoiceData/:Id"
                name="emailInvoiceData"
                render={(props) => <PrintView {...props} />}
              />
              <Route
                exact
                path="/DealPrint"
                name="DealPrint"
                render={(props) => <DealPrint {...props} />}
              />
              <Route
                path="/AdminSuppDashboard"
                name="AdminSuppDashboard"
                render={(props) => <AdminSuppDashboard {...props} />}
              />
              <Route
                exact
                path="/adminViewDetails"
                name="adminViewDetails"
                render={(props) => <AdminViewDetails {...props} />}
              />
              <Route
                exact
                path="/SignUp"
                name="SignUp Page"
                render={(props) => <SignUp {...props} />}
              />

              <Route
                exact
                path="/defaultHeader"
                name="Defaul tHeader"
                render={(props) => <DefaultHeader {...props} />}
              />

              <Route
                path="/"
                name="Home"
                render={(props) => <DefaultLayout {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </>
    );
  }
}
// module.exports = App;
export default App;
