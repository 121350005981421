import React, { useEffect, useState } from "react";
import moment from "moment";
import { IdleTimeOutModal } from "./IdleTimeOutModal";
const IDLE_TIME = 1800000; //30mints
// const IDLE_TIME = 60000; //1 mints
// const IDLE_TIME = 5000; //20 sec

const IdleTimeOutHandler = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [isLogout, setLogout] = useState(false);
  let timer = undefined;
  const events = ["click", "load", "keydown"];
  const eventHandler = (eventType) => {
    // console.log(eventType);
    if (!isLogout) {
      localStorage.setItem("lastInteractionTime", moment());
      if (timer) {
        props.onActive();
        startTimer();
      }
    }
  };

  useEffect(() => {
    // console.log("in useEffect");
    addEvents();

    return () => {
      removeEvents();
      clearTimeout(timer);
    };
  });

  const startTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(
      () => {
        let lastInteractionTime = localStorage.getItem("lastInteractionTime");
        const diff = moment.duration(
          moment().diff(moment(lastInteractionTime))
        );
        let timeOutInterval = props.timeOutInterval
          ? props.timeOutInterval
          : IDLE_TIME;
        // alert("3");

        if (isLogout) {
          clearTimeout(timer);
        } else {
          // alert("2");

          if (diff._milliseconds < timeOutInterval) {
            startTimer();
            props.onActive();
          } else {
            // alert("1");
            // if (showModal) {
            //   setShowModal(false);
            //   window.location.href = "/";
            // }
            props.onIdle();
            setShowModal(true);
            // handleLogout();
          }
        }
      },
      props.timeOutInterval ? props.timeOutInterval : IDLE_TIME
    );
  };
  const addEvents = () => {
    // console.log("useeffect");

    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });

    startTimer();
  };

  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  // const handleContinueSession = () => {
  //   setTimeout(submit, 1000);
  //   setShowModal(false);
  //   clearTimeout(timer);
  //   setLogout(true);
  // };
  const handleLogout = () => {
    removeEvents();
    clearTimeout(timer);
    setLogout(true);
    props.onLogout();
    setShowModal(false);
    window.location.href = "/";
    // setShowModal(false);
  };

  return (
    <div>
      <IdleTimeOutModal
        showModal={showModal}
        handleContinue={() => {
          handleLogout();
          setShowModal(false);
          window.location.href = "/";
        }}
        handleLogout={handleLogout}
      />
      {/* {showModal ? alert("modal show logout") : null} */}
    </div>
  );
};

export default IdleTimeOutHandler;
