// import 'react-app-polyfill/ie9'; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import store from "./store";
import App from "./App";
import { LocaleProvider } from "react-easy-localization";
import * as serviceWorker from "./serviceWorker";
const resources = {
  arabic: {
    title: "إنشاء فاتورة",
    backToOverView: "الرجوع إلى النظرة العامة",
    client: "عميل",
    IssueDate: "تاريخ الإصدار",
    Invoice: "فاتورة",
    poNo: "ص. رقم",
    refference: "مرجعك",
    Currency: "عملة",
    note: "مرئية للعميل في الفاتورة",
    beenPaid: "هل تم دفع ثمنها؟",
    paymentTerms: "شروط الدفع",
    item: "العنصر",
    qty: "كمية",
    rate: "معدل",
    amount: "مقدار",
    discount: "تخفيض",
    netAmount: "كمية الشبكة",
    description: "وصف",
    saleTax: "ضريبة المبيعات",
    firstName: "hgjdg",
  },
  English: {
    title: "CREATE INVOICE",
    backToOverView: "BACK TO OVERVIEW",
    client: "client",
    IssueDate: "Issue Date",
    Invoice: "Invoice",
    poNo: "P.O. No",
    refference: "Your Reference",
    Currency: "Currency",
    note: "Notes visible to the client on the invoice",
    beenPaid: "Has it been paid for?",
    paymentTerms: "Payment Terms:",
    item: "Item",
    qty: "Quantity",
    rate: "Rate",
    amount: "Amount",
    discount: "Discount",
    netAmount: "Net Amount",
    description: "Description",
    saleTax: "Sales Tax",
    firstName: "First Name",
  },
};

ReactDOM.render(
  <Provider store={store}>

  <LocaleProvider resources={resources}>
    <App />
  </LocaleProvider>
  </Provider>,

  document.getElementById("root")
);
// ReactDOM.render(
//   <App />,

//   document.getElementById("root")
// );
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
