import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export const IdleTimeOutModal = ({
  showModal,
  handleContinue,
  handleLogout,
  remainingTime,
}) => {
  if (localStorage.getItem("token") == "null") {
    window.location.href = "/";
  }
  if (showModal) {
    localStorage.setItem("token", null);
    localStorage.setItem("username", null);
  }

  return (
    <>
      {/* {console.log(
        "showModal" +
          showModal +
          window.location.href +
          window.location.href.slice(-5)
      )} */}
      {/* {console.log(window.location.href.slice(-5) == "login")} */}
      <Modal show={showModal} onHide={handleContinue}>
        <Modal.Header closeButton>
          <Modal.Title>You Have Been Idle!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your session is Timed Out.</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleLogout}>
            Logout
          </Button>
          {/* <Button variant="primary" onClick={handleContinue}>
          Continue Session
        </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};
